export const contentList = [
  {
    title: "Client Profile",
    description: [
      "The North American arm of one of the largest asset managers in the world manages over $200 billion in institutional assets specializing in fixed income, liability driven investment (LDI) solutions and index capabilities."
    ]
  },
  {
    title: "Business Situation",
    description: [
      "Bloomberg LP recently completed the acquisition of the Barclays Risk Analytics and Index Solutions business. Due to the acquisition, our client, a former Barclays POINT user, elected to migrate to Bloomberg PORT, which is a comparable system that augments Bloomberg data, news, alerts, mobile capabilites, and order management solutions.",
      "Bloomberg imposed a strict deadline to complete the migration while they continued to support the legacy Barclays offering. Ourclient had undergone the migration process and was experiencing challenges obtaining sign-off on performance, risk, and attribution calculations due to unexplained discrepancies and lack of confidence in the new process."
    ]
  },
  {
    title: "Grandview's Role",
    description: [
      "Grandview was engaged by our client to support the migration and provide subject matter expertise related to the underlying data, asset classes, and calculation methodologies.",
      "To install confidence in the new process, Grandview developed proprietary tools to copare data from each system and perform root cause analysis to explain discrepancies that resulted from bad data, configuration errors, and methodology differences.",
      "Grandview worked with the vendor and front office personnel to resolve configuration errors, build consensus on enterprise methodoloties, and fix data quality issues.",
      "By developing interactive dashboards, data quality metrics, and outlier reports, Grandview was able to provide transparency into the process, which ultimately led to approval of a new system of record for security analytics, portfolio risk, performance, and attribution."
    ]
  }
]

export const businessResultsList = [
  "Validated and/or explained differences for 99% of security, performance, risk, and attribution data points",
  "Saved several weeks of investigation by quickly identifying methodology differences for Asset Backed Securities, which were 5x more likely to influence a calculation break",
  "Saved several weeks of investigation by quickly identifying methodology differences for Asset Backed Securities, which were 5x more likely to influence a calculation break"
]
