import { makeStyles } from "@material-ui/core/styles"

export const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
  },
  innerWrapper: {
    width: "100%",
    maxWidth: 1440,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 60,
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  title: {
    textAlign: "center",
    lineHeight: 1.2,
  },
  listWrapper: {
    marginTop: 40,
  },
  item: {
    //
  },
  itemInnerWrapper: {
    //
  },
  itemTitle: {
    color: "#2b67b1",
    marginBottom: 20,
  },
  itemDescription: {
    marginBottom: 20,
  },
  keyInfluencerImage: {
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      width: "80%",
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
    },
  },
}))
