import * as React from "react"
import { PageProps } from "gatsby"

import Layout from "../../components/Layout"
import SEO from "../../components/Seo"
import SubHero from "../../components/SubHero"
import CaseStudyContent from "../../sections/client-work/CaseStudyContent"

import caseStudyBgUrl from "../../../media/case-study-banner-overlay.jpg"

const caseStudyTitle = "Case Study"
const caseStudyDescription = ""

const CaseStudy = ({ location }: PageProps) => (
  <Layout location={location}>
    <SEO title={caseStudyTitle} />
    <SubHero
      backgroundImage={caseStudyBgUrl}
      title={caseStudyTitle}
      description={caseStudyDescription}
    />
    <CaseStudyContent />
  </Layout>
)

export default CaseStudy
