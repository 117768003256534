import * as React from "react"
import { Typography, Grid } from "@material-ui/core"

import keyInfluencerImg from "../../../../media/client-work/key-influencer-screen.png"

import { contentList, businessResultsList } from "./data"
import { useStyles } from "./styles"

const title = "Global asset manager migrates to Bloomberg PORT for portfolio risk and performance"
const businessResultsTitle = "Business Results"
const keyInfluencerTitle ="Successfully completed the migration and obtained sign-off from impacted stakeholders within the vendor's mandated timeline"

const CaseStudyContent = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.innerWrapper}>
        <Typography variant="subtitle1" className={classes.title}>
          {title}
        </Typography>
        <Grid container spacing={10} className={classes.listWrapper}>
        {contentList.map((item, index) => (
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}
            key={`case-study-content-item-${index}`}
            className={classes.item}
          >
            <div className={classes.itemInnerWrapper}>
              <Typography variant="subtitle2" className={classes.itemTitle}>
                {item.title}
              </Typography>
              {item.description.map((dItem, dIndex) => (
                <Typography
                  key={`content-item-description-${index}-${dIndex}`}
                  variant="body2"
                  className={classes.itemDescription}
                >
                  {dItem}
                </Typography>
              ))}
            </div>
          </Grid>
        ))}
        </Grid>
        <Typography variant="subtitle2" className={classes.itemTitle}>
          {businessResultsTitle}
        </Typography>
        <Grid container spacing={10} className={classes.listWrapper}>
        {businessResultsList.map((bItem, bIndex) => (
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}
            key={`case-study-content-business-result-item-${bIndex}`}
            className={classes.item}
          >
            <div className={classes.itemInnerWrapper}>
              <Typography variant="body2" className={classes.itemDescription}>
                {bItem}
              </Typography>
            </div>
          </Grid>
        ))}
        </Grid>
        <Typography variant="body2" className={classes.itemDescription}>
          {keyInfluencerTitle}
        </Typography>
        <img src={keyInfluencerImg} className={classes.keyInfluencerImage} />
      </div>
    </div>
  )
}

export default CaseStudyContent
